import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

import "./index.css";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="row">
      <div className="col-50">
        <h1>I'm Nick</h1>
        <p>Professional videographer, filmmaker, and video editor.</p>
        <p>
          <Link to="/contact/" style={{ color: `inherit`, textDecoration: `none` }}>View my works</Link> <br />
          <Link to="/about/" style={{ color: `inherit`, textDecoration: `none` }}>Contact me</Link> <br />
        </p>
      </div>
      <div className="col-50" style={{ textAlign: `center` }}>
        <StaticImage
          src="../images/nick.jpg"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="nick"
          style={{ marginBottom: `1.45rem`, borderRadius: `50px` }}
        />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
